import firebase from "../helpers/firebaseInstance.ts";
import type firebaseInstance from "../helpers/firebaseInstance.ts";
import StyledFirebaseAuth from "./StyledFirebaseAuth.tsx";

type LoginModalProps = {
	onUserLoggedIn: (user: firebaseInstance.User) => void;
	id: string;
};

// Configure FirebaseUI.
const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: "popup",
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
			disableSignUp: { status: true },
		},
	],
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false,
	},
};

const LoginModal = ({ onUserLoggedIn, id }: LoginModalProps) => {
	return (
		<dialog id={id} className="modal">
			<div className="modal-box">
				<form method="dialog">
					{/* if there is a button in form, it will close the modal */}
					<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
						✕
					</button>
				</form>
				<h3 className="font-bold text-lg">Login</h3>
				<div>
					<p className="py-4"> Para continuarmos, por favor faça o login</p>
					<StyledFirebaseAuth
						onUserLoggedIn={onUserLoggedIn}
						uiConfig={uiConfig}
						firebaseAuth={firebase.auth()}
					/>
				</div>
			</div>
		</dialog>
	);
};

export default LoginModal;
