import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import type { TLabelValue } from "../types.ts";

type SelectInputProps = {
	label: string;
	name: string;
	placeholder?: string;
	required?: boolean;
	options: TLabelValue[];
};

const SelectInput = ({
	required,
	name,
	placeholder,
	label,
	options,
}: SelectInputProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const hasError = !!errors[name];

	return (
		<label className="form-control w-56">
			<div className="label">
				<span className="label-text">{label}</span>
			</div>
			<select
				{...register(name, {
					validate: (value) => required && value !== placeholder,
				})}
				className={clsx("select select-bordered", {
					"!border-red-500": hasError,
				})}
			>
				<option disabled selected>
					{placeholder}
				</option>
				{options.map(({ label, value }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			{hasError && (
				<div className="label">
					<span className="label-text-alt text-red-500 flex gap-2">
						<ExclamationTriangleIcon className="size-4" />
						Campo obrigatório
					</span>
				</div>
			)}
		</label>
	);
};

export default SelectInput;
