import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImagePicker from "../../components/ImagePicker.tsx";
import InputText from "../../components/InputText.tsx";
import PartnerDetails, {
	type PartnerInputs,
} from "../../components/PartnerDetails.tsx";
import createPartnerPayload from "../../helpers/createPartnerPayload.ts";
import { Enums } from "../../helpers/enums.ts";
import firebaseInstance from "../../helpers/firebaseInstance.ts";
import { queryClient } from "../../helpers/queryClient.ts";
import type { TLabelValue, TPartnerDetails } from "../../types.ts";

type AddPartnerProps = { categories: TLabelValue[] };

type Inputs = {
	search: string;
};

const AddPartner = ({ categories }: AddPartnerProps) => {
	const location = useLocation();
	const formProps = useForm<Inputs>();
	const navigate = useNavigate();

	const { mutate, data, isPending, reset } = useMutation({
		mutationFn: (search: string) =>
			axios
				.get<TPartnerDetails>("getGoogleMapsLocation", {
					params: {
						search,
					},
				})
				.then((res) => res.data),
	});

	useEffect(() => {
		const partnerDetails = document.getElementById(Enums.addPartner);

		if (partnerDetails) {
			// @ts-expect-error bla
			partnerDetails.checked = location.pathname.includes("add");
		}
	}, [location]);

	const handleAddPartnerSuccess = () => {
		formProps.reset();
		reset();
		navigate("/admin");
		queryClient.invalidateQueries({ queryKey: ["partners"] });
	};

	const onSubmit: SubmitHandler<Inputs> = ({ search }) => {
		mutate(search);
	};
	return (
		<div className="flex flex-col gap-4">
			<Link
				to={"/admin"}
				className="btn absolute btn-circle btn-ghost drawer-button right-4 top-2"
			>
				<XCircleIcon className="size-8" />
			</Link>
			<FormProvider {...formProps}>
				<div className="flex flex-col gap-2">
					<form
						onSubmit={formProps.handleSubmit(onSubmit)}
						className="flex  items-end gap-8"
					>
						<InputText name="search" label="Buscar" size="full" />
						<button className="btn btn-primary w-60">
							{isPending && <span className="loading loading-spinner" />} Buscar
						</button>
					</form>
					{!!data?.photos && (
						<div className="flex flex-row-reverse">
							<div className="flex flex-col  items-center">
								<button
									className="btn btn-link btn-primary w-60"
									onClick={() =>
										document
											.getElementById(Enums.pickCustomerModal)
											// @ts-expect-error bla
											?.showModal()
									}
								>
									Selecionar imagem do google
								</button>
							</div>
						</div>
					)}
				</div>
			</FormProvider>
			{!!data && (
				<PartnerDetailsFormWrapper
					data={data}
					categories={categories}
					onAddPartnerSuccess={handleAddPartnerSuccess}
				/>
			)}
		</div>
	);
};

const PartnerDetailsFormWrapper = ({
	data: { photos, ...data },
	categories,
	onAddPartnerSuccess,
}: {
	data: any;
	categories: TLabelValue[];
	onAddPartnerSuccess: () => void;
}) => {
	const formProps = useForm<PartnerInputs>({
		defaultValues: data,
	});

	const { mutate, isPending } = useMutation({
		mutationFn: async (partner: any) => {
			const token = await firebaseInstance.auth().currentUser?.getIdToken(true);
			return axios
				.post<TPartnerDetails>("savePartner", partner, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => res.data);
		},
		onSuccess: (_, input) => {
			toast.success(`Sucesso! ${input.name} cadastrado com sucesso`);
			onAddPartnerSuccess();
		},
		onError: (error) => {
			const e = typeof error === "string" ? error : error.message;
			toast.error(`Erro! ${e}`);
		},
	});

	const onSubmit: SubmitHandler<PartnerInputs> = async (props) => {
		const payload = await createPartnerPayload(props);

		mutate(payload);
	};

	return (
		<FormProvider {...formProps}>
			<form
				onSubmit={formProps.handleSubmit(onSubmit)}
				className="flex flex-col gap-8"
			>
				<PartnerDetails
					showTitle={false}
					categories={categories}
					showAddressSection
					isLoading={isPending}
				/>
				{!!photos && <ImagePicker photos={photos} name={data.name} />}
			</form>
		</FormProvider>
	);
};

export default AddPartner;
