import _ from "lodash";

const pruneEmpty = <T extends Record<string, any>>(obj: T) => {
	return (function prune(current) {
		_.forOwn(current, (value, key) => {
			if (
				_.isUndefined(value) ||
				_.isNull(value) ||
				_.isNaN(value) ||
				(_.isNumber(value) && value === 0) ||
				(_.isString(value) && _.isEmpty(value)) ||
				(_.isObject(value) && _.isEmpty(prune(value)))
			) {
				delete current[key];
			}
		});
		// remove any leftover undefined values from the delete
		// operation on an array
		if (_.isArray(current)) _.pull(current, undefined);

		return current;
	})(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
};

export default pruneEmpty;
