import { onAuthStateChanged } from "firebase/auth";
import * as firebaseui from "firebaseui";
import { useEffect, useRef } from "react";
import "../assets/firebase-ui-auth__pt_br.js";
import "firebaseui/dist/firebaseui.css";
import "./firebaseUI.css";
import type firebaseInstance from "../helpers/firebaseInstance.ts";

interface Props {
	// The Firebase UI Web UI Config object.
	// See: https://github.com/firebase/firebaseui-web#configuration
	uiConfig: firebaseui.auth.Config;
	// Callback that will be passed the FirebaseUi instance before it is
	// started. This allows access to certain configuration options such as
	// disableAutoSignIn().
	uiCallback?(ui: firebaseui.auth.AuthUI): void;
	// The Firebase App auth instance to use.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	firebaseAuth: any; // As firebaseui-web
	className?: string;
	onUserLoggedIn: (user: firebaseInstance.User) => void;
}

const StyledFirebaseAuth = ({
	uiConfig,
	firebaseAuth,
	className,
	uiCallback,
	onUserLoggedIn,
}: Props) => {
	const elementRef = useRef(null);

	useEffect(() => {
		// Get or Create a firebaseUI instance.
		const firebaseUiWidget =
			firebaseui.auth.AuthUI.getInstance() ||
			new firebaseui.auth.AuthUI(firebaseAuth);
		if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

		// We track the auth state to reset firebaseUi if the user signs out.
		const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
			if (user) onUserLoggedIn(user as firebaseInstance.User);
		});

		// Trigger the callback if any was set.
		if (uiCallback) uiCallback(firebaseUiWidget);

		// Render the firebaseUi Widget.
		// @ts-expect-error - The firebaseui type definitions are incorrect.
		firebaseUiWidget.start(elementRef.current, uiConfig);

		return () => {
			unregisterAuthObserver();
			firebaseUiWidget.reset();
		};
	}, [firebaseui, uiConfig]);

	return <div className={className} ref={elementRef} />;
};

export default StyledFirebaseAuth;
