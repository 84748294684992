import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import firebaseInstance from "../helpers/firebaseInstance.ts";

const Login = () => {
	const navigate = useNavigate();

	const { mutate } = useMutation({
		mutationFn: () => {
			return firebaseInstance
				.auth()
				.signInWithPopup(new firebaseInstance.auth.GoogleAuthProvider())
				.then((result) => {
					return result.user;
				});
		},
		onSuccess: () => {
			navigate(-1);
		},
	});

	return (
		<div className="flex flex-col min-h-screen justify-center">
			<h3 className="font-bold text-lg">Login</h3>
			<div>
				<p className="py-4"> Para continuarmos, por favor faça o login</p>
				<button className="btn mt-4 btn-primary" onClick={() => mutate()}>
					Login com Google
				</button>
			</div>
		</div>
	);
};

export default Login;
