import type * as React from "react";

type CheckInInfoProps = {
	discount: number;
	weekday: string;
	from: string;
	to: string;
};

const weekDays = {
	mon: "Segunda",
	tue: "Terça",
	wed: "Quarta",
	thu: "Quinta",
	fri: "Sexta",
	sat: "Sábado",
	sun: "Domingo",
};

const CheckInInfo: React.FC<React.PropsWithChildren<CheckInInfoProps>> = ({
	weekday,
	discount,
	from,
	to,
}) => {
	return (
		<div className="stats stats-vertical lg:stats-horizontal bg-inherit ">
			<div className="stat text-center">
				<div className="stat-title">Dia da semana</div>
				{/*@ts-expect-error all good */}
				<div className="stat-value">{weekDays[weekday]}</div>
			</div>

			<div className="stat text-center">
				<div className="stat-title">Horário</div>
				<div className="stat-value">
					{from.split(":")[0]}h-{to.split(":")[0]}h
				</div>
			</div>

			<div className="stat text-center">
				<div className="stat-title">Desconto</div>
				<div className="stat-value primary">{discount * 100}%</div>
			</div>
		</div>
	);
};

export default CheckInInfo;
