import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import clx from "classnames";
import queryString from "query-string";
import * as React from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import logo from "../../assets/logo.png";
import CheckInConfirmationModal from "../../components/CheckInConfirmationModal";

const CPF_MASK = "999.999.999-99";

type Inputs = {
	cpfOrEmail: string;
};

type MutationInput = {
	cpf?: string;
	email?: string;
	partnerId: string;
};

const App = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		resetField,
	} = useForm<Inputs>();

	const { partnerId } = queryString.parse(location.search);

	const alertRef = React.useRef<HTMLDivElement | undefined>();

	const [showCPFMask, setShowCPFMask] = React.useState(false);

	const {
		mutate: fetchCheckin,
		isPending,
		data,
		isError,
		reset,
	} = useMutation({
		mutationFn: (input: MutationInput) =>
			axios.post("fetchCheckin", input).then((res) => res.data),
	});

	const { mutate: confirmCheckin, isPending: isConfirmCheckinPending } =
		useMutation({
			mutationFn: (input: MutationInput & { id?: string; savings?: number }) =>
				axios.put("confirmCheckin", input).then((res) => res.data),
			onSuccess: () => {
				reset();
				resetField("cpfOrEmail");
				toast.success("Desconto validado com sucesso!");
			},
		});

	const onConfirmCheckin = (providedDiscount?: number) => {
		const cpfOrEmail = getValues("cpfOrEmail");

		const input = showCPFMask ? { cpf: cpfOrEmail } : { email: cpfOrEmail };

		confirmCheckin({
			...input,
			partnerId: partnerId as string,
			id: data?.id,
			savings: providedDiscount,
		});
	};

	React.useEffect(() => {
		if (alertRef.current)
			alertRef.current.scrollIntoView({ behavior: "smooth" });
	}, [data]);

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const input = showCPFMask
			? { cpf: data.cpfOrEmail }
			: { email: data.cpfOrEmail };

		fetchCheckin({ ...input, partnerId: partnerId as string });
	};

	if (!partnerId) {
		return (
			<div className="flex flex-col items-center gap-5 container">
				<img src={logo} height={400} />
				<div className="text-center">
					<h1 className="text-3xl font-bold">Erro</h1>
					<p className="text-lg">
						Página do parceiro não encontrada. Por favor, verifique a URL e
						tente novamente.
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex container max-w-4xl flex-col items-center gap-5 p-4">
			<img src={logo} height={400} alt="Logo" />

			<form
				className="w-full -mt-20 flex items-end gap-4 flex-wrap"
				onSubmit={handleSubmit(onSubmit)}
			>
				<label className="form-control md:flex-1 w-full" htmlFor="cpfOrEmail">
					<div className="label">
						<span className="label-text">Validar desconto</span>
					</div>
					<div
						className={clx("input input-bordered flex items-center gap-2", {
							"input-error": !!errors.cpfOrEmail,
						})}
					>
						<InputMask
							id="cpfOrEmail"
							{...register("cpfOrEmail", {
								validate: {
									required: (value) => {
										return !!value || "Campo obrigatório";
									},
									cpf: (value) => {
										if (!showCPFMask) {
											return true;
										}

										return value.length === 14 ? true : "CPF inválido";
									},
									email: (value) => {
										if (showCPFMask) {
											return true;
										}

										return value.includes("@") ? true : "E-mail inválido";
									},
								},
								onChange: (e) => {
									setShowCPFMask(
										e.target.value.length > 2 &&
											!Number.isNaN(Number(e.target.value.slice(0, 3))),
									);
								},
							})}
							mask={showCPFMask ? CPF_MASK : ""}
							type="text"
							placeholder="Digite o e-mail ou CPF aqui"
							className="grow flex flex-1"
							// @ts-expect-error maskPlaceholder is not in the types
							maskPlaceholder={null}
						/>

						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 16 16"
							fill="currentColor"
							className="h-4 w-4 opacity-70"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				</label>
				<button
					type="submit"
					className="btn btn-primary text-white"
					disabled={isPending}
				>
					{isPending && <span className="loading loading-spinner" />}Validar
				</button>
			</form>
			{!!errors.cpfOrEmail && (
				<div className="flex items-center gap-2 mt-2 text-error">
					{/*@ts-expect-error ion icons */}
					<ion-icon name="warning" />
					<span className="label-text-alt text-error">
						{errors.cpfOrEmail.message}
					</span>
				</div>
			)}

			<CheckInConfirmationModal
				isOpen={!!data}
				onClose={() => reset()}
				discountData={data?.discount}
				checkinStatus={data?.checkinStatus}
				isError={isError}
				isLoading={isConfirmCheckinPending}
				onConfirmCheckIn={onConfirmCheckin}
			/>
		</div>
	);
};

export default App;
