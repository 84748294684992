import clsx from "clsx";
import type * as React from "react";

type DrawerProps = { content: React.ReactNode; id: string };

const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
	children,
	content,
	id,
}) => {
	return (
		<div className={clsx("drawer drawer-end")}>
			<input id={id} type="checkbox" className="drawer-toggle" />
			<div className="drawer-content">{children}</div>
			<div className="drawer-side z-10">
				<label
					htmlFor={id}
					aria-label="close sidebar"
					className="drawer-overlay"
				/>
				<div className="bg-gray-800 min-h-full w-[80%] p-6">
					{/* Sidebar content here */}
					{content}
				</div>
			</div>
		</div>
	);
};

export default Drawer;
