import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUrl } from "./helpers/gerUrl.ts";
import { queryClient } from "./helpers/queryClient.ts";
import Contact from "./pages/Contact.tsx";
import DeleteAccount from "./pages/DeleteAccount.tsx";
import Login from "./pages/Login.tsx";
import Privacy from "./pages/Privacy.tsx";
import Terms from "./pages/Terms.tsx";
import { ProtectedRoute } from "./pages/admin/AdminRoute.tsx";
import PartnersTable from "./pages/admin/PartnersTable.tsx";
import Validation from "./pages/partner/Validation.tsx";

Sentry.init({
	dsn: "https://275e4be435a6778e3e1dad477eb79ba1@o4507832909692928.ingest.us.sentry.io/4507975971766272",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/partner/validate",
		element: <Validation />,
	},
	{
		path: "/privacy",
		element: <Privacy />,
	},
	{
		path: "/terms",
		element: <Terms />,
	},
	{
		path: "/contact",
		element: <Contact />,
	},
	{
		path: "/delete-account",
		element: <DeleteAccount />,
	},
	{
		path: "/admin",
		element: (
			<ProtectedRoute>
				<PartnersTable />
			</ProtectedRoute>
		),
		children: [
			{
				path: "add",
				element: <PartnersTable />,
			},
		],
	},
]);

axios.interceptors.request.use((config) => {
	if (!config?.url || config.url.includes("https:")) {
		return config;
	}

	return { ...config, url: getUrl(config.url) };
});

axios.interceptors.response.use(
	(response) => {
		// Optional: Do something with response data
		return response;
	},
	(error) => {
		// Do whatever you want with the response error here:

		// But, be SURE to return the rejected promise, so the caller still has
		// the option of additional specialized handling at the call-site:
		return Promise.reject(error.response.data);
	},
);

const Main = () => {
	const formMethods = useForm();

	return (
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<FormProvider {...formMethods}>
					<RouterProvider router={router} />
					<ToastContainer
						position="top-center"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
					/>
				</FormProvider>
			</QueryClientProvider>
		</React.StrictMode>
	);
};

ReactDOM.createRoot(document.getElementById("root")!).render(<Main />);
