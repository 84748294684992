import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns/format";
import { Link, useNavigate } from "react-router-dom";
import DeletePartnerModal from "../../components/DeletePartnerModal.tsx";
import Drawer from "../../components/Drawer.tsx";
import { Enums } from "../../helpers/enums.ts";
import firebaseInstance from "../../helpers/firebaseInstance.ts";
import type { TLabelValue, TMeta, TPartner } from "../../types.ts";
import EditPartner from "../partner/EditPartner.tsx";
import AddPartner from "./AddPartner.tsx";

const getCategoryLabel = (categories: TLabelValue[], category: string) => {
	return categories.find((c) => c.value === category)?.label;
};

function PartnersTable() {
	const {
		data: { data } = {},
	} = useQuery<{
		data: TPartner[];
		meta: TMeta;
	}>({
		queryKey: ["partners"],
		queryFn: async () => {
			const token = await firebaseInstance.auth().currentUser?.getIdToken(true);
			return axios
				.get("fetchPartners", { headers: { Authorization: `Bearer ${token}` } })
				.then((res) => res.data);
		},
	});
	const { data: categories } = useQuery<TLabelValue[]>({
		queryKey: ["categories"],
		queryFn: () => axios.get("fetchCategories").then((res) => res.data),
	});

	const navigate = useNavigate();

	if (!data || !categories) {
		return <div>Carregando...</div>;
	}

	return (
		<Drawer
			id={Enums.addPartner}
			content={<AddPartner categories={categories} />}
		>
			<Drawer
				id={Enums.editPartner}
				content={
					<EditPartner categories={categories} showAddressSection={false} />
				}
			>
				<div className="mx-auto max-w-7xl">
					<div className=" py-10">
						<div className="px-4 sm:px-6 lg:px-8">
							<div className="sm:flex sm:items-center">
								<div className="sm:flex-auto">
									<h1 className="text-base font-semibold leading-6 text-white">
										Parceiros
									</h1>
									<p className="mt-2 text-sm text-gray-300">
										A lista de todos os parceiros cadastrados no BenefizClub
									</p>
								</div>
								<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
									<Link
										to={{ pathname: "add" }}
										className="block rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary "
									>
										Adicionar parceiro
									</Link>
								</div>
							</div>
							<div className="mt-8 flow-root">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-700">
											<thead>
												<tr>
													<th
														scope="col"
														className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
													>
														ID
													</th>
													<th
														scope="col"
														className="py-3.5 pl-4 pr-3 text-sm font-semibold text-white sm:pl-0"
													>
														Criado em
													</th>
													<th
														scope="col"
														className="py-3.5 pl-4 pr-3 text-sm font-semibold text-white sm:pl-0"
													>
														Nome
													</th>
													<th
														scope="col"
														className="px-3 py-3.5  text-sm font-semibold text-white"
													>
														Desconto máximo
													</th>
													<th
														scope="col"
														className="px-3 py-3.5  text-sm font-semibold text-white"
													>
														Categoria
													</th>
													<th
														scope="col"
														className="px-3 py-3.5  text-sm font-semibold text-white"
													>
														Bairro
													</th>
													<th
														scope="col"
														className="px-3 py-3.5  text-sm font-semibold text-white"
													>
														Ações
													</th>
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-800">
												{data.map((d) => (
													<tr
														key={d.id}
														className="hover:bg-gray-800"
														role="button"
														onClick={() => navigate(`?id=${d.id}`)}
													>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
															{d.id}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300 text-center">
															{format(
																new Date(d.createdAt),
																"dd/MM/yyyy HH:mm",
															)}
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0 text-center">
															{d.name}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300 text-center">
															{`${d.maxDiscount * 100}%`}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300 text-center">
															<span className="badge badge-accent">
																{getCategoryLabel(categories, d.category)}
															</span>
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300 text-center">
															{d.neighborhood}
														</td>

														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0   flex gap-6">
															<a
																onClick={(e) => e.stopPropagation()}
																className="flex flex-col items-center gap-1 hover:text-gray-300 "
																href={`https://clubedvantagens.com.br/partner/validate?partnerId=${btoa(`UserId:${d.id}`)}`}
																target="_blank"
																rel="noreferrer"
															>
																{/* @ts-expect-error bla*/}
																<ion-icon
																	name="open-outline"
																	style={{ fontSize: "20px" }}
																/>
																<span className="text-xs">Link</span>
															</a>
															<button
																type="button"
																className="flex flex-col items-center gap-1 hover:text-gray-300 "
																onClick={(e) => {
																	e.stopPropagation();
																	document
																		.getElementById(
																			`${Enums.deleteCustomerModal}_${d.id}`,
																		)
																		// @ts-expect-error bla
																		?.showModal();
																}}
															>
																{/* @ts-expect-error bla*/}
																<ion-icon
																	name="trash"
																	style={{ fontSize: "20px" }}
																/>
																<span className="text-xs">Deletar</span>
															</button>
														</td>
														<DeletePartnerModal name={d.name} id={d.id} />
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Drawer>
		</Drawer>
	);
}

export default PartnersTable;
