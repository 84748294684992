import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import queryString from "query-string";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PartnerDetails, {
	type PartnerInputs,
	type Discount,
} from "../../components/PartnerDetails.tsx";
import createPartnerPayload from "../../helpers/createPartnerPayload.ts";
import { Enums } from "../../helpers/enums.ts";
import firebaseInstance from "../../helpers/firebaseInstance.ts";
import { queryClient } from "../../helpers/queryClient.ts";
import type { TDiscount, TLabelValue, TPartnerDetails } from "../../types.ts";

type Props = {
	categories: TLabelValue[];
	showAddressSection: boolean;
};

const buildDiscounts = (discounts: TDiscount[]): Record<string, Discount[]> => {
	return discounts.reduce(
		(acc, { discount, restrictions, to, from, weekday, id }) => {
			// @ts-expect-error bla
			acc[weekday].push({
				id,
				discount: discount * 100,
				from,
				to,
				restrictions: restrictions?.map((r) => ({ restriction: r })),
			});

			return acc;
		},
		{
			mon: [],
			tue: [],
			wed: [],
			thu: [],
			fri: [],
			sat: [],
			sun: [],
		},
	);
};

const EditPartner = (props: Props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const { id } = queryString.parse(location.search);

	const { data } = useQuery<{
		partner: TPartnerDetails;
		discounts: TDiscount[];
	}>({
		queryKey: ["fetchPartner", id],
		queryFn: () =>
			axios.get("fetchPartner", { params: { id } }).then((res) => res.data),
		enabled: !!id,
	});

	const handleEditPartnerSuccess = () => {
		navigate("/admin");
		Promise.all([
			queryClient.invalidateQueries({ queryKey: ["partners"] }),
			queryClient.invalidateQueries({ queryKey: ["fetchPartner", id] }),
		]);
	};

	useEffect(() => {
		const { id } = queryString.parse(location.search);

		const partnerDetails = document.getElementById(Enums.editPartner);

		if (partnerDetails) {
			// @ts-expect-error bla
			partnerDetails.checked = !!id;
		}
	}, [location]);

	if (!data) {
		return <div>Carregando...</div>;
	}

	return (
		<div className="flex flex-col gap-8">
			<Link
				to={"/admin"}
				className="btn absolute btn-circle btn-ghost drawer-button right-4 top-2"
			>
				<XCircleIcon className="size-8" />
			</Link>
			<PartnerDetailsFormWrapper
				{...props}
				{...data}
				onEditPartnerSuccess={handleEditPartnerSuccess}
			/>
		</div>
	);
};

const PartnerDetailsFormWrapper = ({
	partner,
	discounts,
	categories,
	onEditPartnerSuccess,
}: {
	partner: TPartnerDetails;
	discounts: TDiscount[];
	categories: TLabelValue[];
	onEditPartnerSuccess: () => void;
}) => {
	const formProps = useForm<PartnerInputs>({
		defaultValues: {
			...partner,
			uploadPhoto: partner.mainPhoto,
			discounts: discounts ? buildDiscounts(discounts) : undefined,
		},
	});

	const { mutate, isPending } = useMutation({
		mutationFn: async (partner: any) => {
			const token = await firebaseInstance.auth().currentUser?.getIdToken(true);
			return axios
				.post<TPartnerDetails>("updatePartner", partner, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => res.data);
		},
		onSuccess: (_, input) => {
			toast.success(`Sucesso! ${input.name} foi editado com sucesso`);
			onEditPartnerSuccess();
		},
		onError: (error) => {
			const e = typeof error === "string" ? error : error.message;
			toast.error(`Erro! ${e}`);
		},
	});

	const onSubmit: SubmitHandler<PartnerInputs> = async (props) => {
		const {
			id,
			name,
			category,
			phone,
			instagram,
			website,
			email,
			overview,
			discounts,
			uploadPhoto,
			googlePlaceId,
		} = await createPartnerPayload(props);

		mutate({
			id,
			name,
			category,
			phone,
			instagram,
			website,
			email,
			overview,
			discounts,
			uploadPhoto,
			googlePlaceId,
		});
	};

	return (
		<FormProvider {...formProps}>
			<form onSubmit={formProps.handleSubmit(onSubmit)}>
				<PartnerDetails
					categories={categories}
					showAddressSection={false}
					isLoading={isPending}
				/>
			</form>
		</FormProvider>
	);
};

export default EditPartner;
