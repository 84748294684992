const Terms = () => {
	return (
		<div className="flex container max-w-4xl flex-col  gap-5 p-4">
			<h1 className="font-bold text-3xl">
				TERMOS E CONDIÇÕES DE USO BenefizClub
			</h1>
			<p className="font-bold text-xl">Do Objeto</p>
			<p>
				O presente instrumento estabelece os termos e condições gerais de uso
				dos serviços oferecidos pelo CLUBED VANTAGENS EXPRESS. (BenefizClub),
				disponibilizados no site www.clubedvantagens.com.br ou pelo aplicativo
				de dispositivos móveis (Plataforma). O teor destes Termos de Uso poder
				ser consultado, impresso ou armazenado em um arquivo de dados, em
				qualquer momento, pelo link http://www.
				clubedvantagens.com.br/termos-e-condicoes. Dos Usuários Toda pessoa que
				desejar utilizar os serviços oferecidos pelo BenefizClub (“Usuário(s)”)
				deverá, inicialmente, ler integralmente estes “Termos de Uso”,
				certificar-se de haver compreendido e aceitar todas as condições
				estabelecidas aqui. Em seguida, o Usuário deverá efetuar o seu cadastro
				gratuitamente pelo Site ou Aplicativo, preenchendo o formulário de
				inscrição com todos os dados solicitados. O Usuário está ciente, ainda,
				de que o BenefizClub se reserva o direito de exigir qualquer comprovante
				e/ou dados adicionais do Usuário, a fim de conferir as informações
				pessoais submetidas no ato do cadastro, bem como, o BenefizClub poderá
				recusar, suspender temporária ou definitivamente, o registro de qualquer
				Usuário cujos dados não tenham sido confirmados, mesmo que o cadastro
				inicial deste já tenha sido aceito, decisão esta que independerá de
				motivos e nem conferirá quaisquer direitos aos Usuários. Ao utilizar os
				serviços oferecidos pelo BenefizClub, o Usuário confirma que leu,
				compreendeu e concorda, de forma livre e independente de qualquer
				alegação de dolo, coação, fraude ou reserva mental, com estes Termos de
				Uso em seu inteiro teor e demais documentos disponíveis na Plataforma,
				firmando-se, assim, um contrato de prestação de serviços entre o Usuário
				e o BenefizClub, por meio do qual ambos passam a ter direitos e
				obrigações. A ACEITAÇÃO DESTES TERMOS DE USO É ABSOLUTAMENTE
				INDISPENSÁVEL À UTILIZAÇÃO DOS SERVIÇOS PRESTADOS PELO BenefizClub,
				ATRAVÉS DA PLATAFORMA. O BenefizClub poderá cancelar o cadastro de
				qualquer Usuário, sem necessidade de prévio aviso, cujos atos sejam
				inadequados às políticas de relacionamento do BenefizClub E que violem a
				legislação local ou as condições destes Termos de Uso, incluindo, mas
				não se limitando, à suspeita de comportamentos fraudulentos,
				descumprimento de obrigações ou em atentado contra a imagem e bom nome
				do BenefizClub.
			</p>

			<h2 className="font-bold text-xl">Dos Benefícios aos Usuários</h2>

			<p>
				O BenefizClub foi desenvolvido com a finalidade de oferecer benefícios
				exclusivos, em forma de descontos, concedidos diretamente pelos
				estabelecimentos parceiros aos Usuários cadastrados, por meio do acesso
				à Plataforma (“Benefícios”). Para poder usufruir dos Benefícios, após se
				cadastrar, o Usuário deverá acessar a Plataforma, selecionar o
				estabelecimento parceiro desejado, verificar a disponibilidade e
				confirmar os Benefícios oferecidos por cada um. Tendo, o Usuário,
				relação jurídica direta com os estabelecimentos parceiros, na qualidade
				de consumidor dos serviços que lhe serão prestados com os descontos, é
				de inteira e exclusiva responsabilidade Usuário verificar, previamente,
				qual a modalidade de Benefício, percentual de descontos, datas, horários
				e especificidades oferecidas por cada estabelecimento parceiro, antes de
				finalizar sua reserva. O BenefizClub apenas intermedia a relação
				jurídica existente entre o Usuário e os estabelecimentos parceiros, não
				tendo nenhuma responsabilidade pela negativa da promoção, uso inadequado
				da Assinatura pelo Usuário ou qualquer ato praticado pelos
				estabelecimentos parceiros. O Usuário está ciente, desde já, que os
				estabelecimentos parceiros podem, a qualquer tempo, se retirar ou ser
				excluído da relação de participantes ou, ainda, modificar, a qualquer
				momento, os termos e condições da sua disponibilidade e/ou Benefícios
				concedidos, não tendo, o BenefizClub, nenhuma responsabilidade pela
				descontinuidade da parceria por determinados estabelecimentos parceiros
				ou eventuais alterações em seus termos e condições ou disponibilidade,
				que deverá ser sempre verificada pelo Usuário antes de concluir sua
				solicitação de desconto ou ir ao estabelecimento parceiro. Os Usuários
				castrados possuem acesso às seguintes modalidades de Benefícios:
			</p>

			<ul>
				<li>● CHECK IN</li>

				<p>
					Pela modalidade de benefício CHECK IN o Usuário deve realizar uma
					busca de estabelecimentos parceiros, verificar a disponibilidade e
					condições dos Benefícios do estabelecimento desejado ( percentual de
					descontos/benefícios, etc.) e efetuar um “Check In” em seu perfil
					cadastrado no Aplicativo para mostrar a tela de confirmação pelo
					celular ao chegar no estabelecimento. Ao utilizar o CHECK IN, o
					Usuário terá acesso ao benefício vigente para aquele momento indicado
					pelo app e registrado em sua confirmação de uso, o que dependerá única
					e exclusivamente das regras de cada estabelecimento parceiro, as quais
					o Usuário deve verificar antes de comparecer e fazer o “Check In”. O
					Usuário está ciente de que, independentemente do benefício ou
					percentual de desconto ou forma de aplicação do Benefício, os valores
					referentes a gorjeta ou serviço do estabelecimento serão calculados
					sempre sobre o valor total da conta antes da aplicação do desconto. O
					Usuário está ciente, inclusive, de que o desconto/Benefício concedido
					pelo uso da Plataforma BenefizClub não é cumulativo com demais
					promoções e pode ser limitado por quaisquer exceções registradas
					dentro das regras no perfil do estabelecimento. Esta modalidade é
					aplicável apenas com a utilização do Aplicativo, uma vez que o Usuário
					deve mostrar a sua tela de confirmação do “Check In” pelo celular ao
					chegar no estabelecimento parceiro.
				</p>

				<li>● “USO LOCAL VIA CPF”</li>

				<p>
					A modalidade de benefício USO LOCAL VIA CPF funciona exatamente como a
					modalidade do CHECK IN, com a única diferença que o Usuário poderá
					apenas informar seu CPF ao chegar no estabelecimento parceiro em vez
					de efetuar o “Check In” pelo Aplicativo.
				</p>
			</ul>

			<p className="font-bold text-xl">Das Assinaturas</p>

			<p>
				Para poder utilizar os serviços BenefizClub e usufruir dos Benefícios, o
				Usuário poderá optar pela assinatura de um dos Planos disponíveis na
				Plataforma e, assim, poderá efetuar reservas ilimitadas, sem o pagamento
				da Taxa de Reserva (“Assinatura”). Ao assinar o BenefizClub, o Usuário
				poderá optar: (i) pelo Plano Semestral ou (ii) pelo Plano Mensal. Os
				valores das Assinaturas dos Planos Semestral e Mensal, assim como as
				formas de pagamento estão disponíveis na Plataforma e poderão sofrer
				alterações a qualquer momento, a exclusivo critério do BenefizClub, bem
				como em casos de campanhas promocionais realizadas em conjunto com
				parceiros ou promoções disponibilizadas diretamente aos assinantes. A
				cobrança da Assinatura será efetuada mensal ou semestralmente, conforme
				o Plano escolhido pelo Usuário, na data de adesão ao respectivo Plano. O
				Usuário poderá cancelar a Assinatura de seu Plano através de seu próprio
				perfil no BenefizClub a qualquer momento e sem nenhum ônus. Na hipótese
				de cancelamento, a sua Assinatura permanecerá ativa até o final do
				período pago e o Usuário não terá direito ao reembolso dos valores já
				efetuados pelo respectivo Plano. O Usuário poderá alterar o tipo de
				assinatura escolhida, porém apenas poderá fazê-lo ao término do período
				de vigência da assinatura em curso.
			</p>

			<p className="font-bold text-xl">Dos Pagamentos</p>

			<p>
				Caso o Usuário necessite alterar os seus dados cadastrados, a qualquer
				momento, este deverá comunicar o BenefizClub imediatamente. Em regra, o
				BenefizClub se comunicará com o Usuário por meio do e-mail informado no
				cadastro, bem como através do seu contato telefônico, incluindo, mas não
				se limitando à sms, aplicativos de mensagens e pushes. O Usuário deve
				adotar todas as medidas necessárias (i.e., configuração de programas
				AntiSpam e filtros de e-mails) para assegurar que os e-mails enviados
				pelo BenefizClub serão entregues ao Usuário. Os Usuários são
				responsáveis por manter em segredo a sua senha, não a
				transmitindo/revelando a terceiros. Se os Usuários tiverem conhecimento
				de que terceiros se apoderaram de sua senha ou estejam utilizando os
				seus dados de acesso, o BenefizClub deverá ser imediatamente informado.
				Caso o BenefizClub tenha motivos para acreditar que uma terceira parte
				não autorizada está na posse de dados de acesso do Usuário, o
				BenefizClub poderá, a seu exclusivo critério, alterar e/ou bloquear os
				dados de acesso. Neste caso, o BenefizClub informará ao Usuário e
				transmitirá, mediante solicitação expressa, os novos dados de acesso. O
				Usuário não terá direito à reprodução dos dados de acesso originais. O
				Usuário está ciente de acordo que não poderá transferir os dados de sua
				conta à terceiros em nenhuma hipótese.
			</p>

			<p className="font-bold text-xl">
				Dos Direitos e Obrigações dos Usuários
			</p>

			<p>
				Os pagamentos serão efetuados através de uma empresa responsável pela
				gestão dos pagamentos. O BenefizClub não poderá intervir nos resultados
				da relação do Usuário com a empresa responsável pela gestão de
				pagamentos, uma vez que a mesma administra suas operações de maneira
				independente. O BenefizClub não será responsável, inclusive, pela
				disponibilidade ou não dos serviços prestados pela empresa gestora de
				pagamentos e não poderá garantir, de forma nenhuma, que estes serviços
				serão livres de erros, interrupções, mal funcionamento, atrasos ou
				outras imperfeições. Do Uso dos Serviços O Usuário concorda em não
				utilizar os serviços do BenefizClub para nenhuma outra finalidade,
				principalmente se ilegal ou proibida por estes Termos de Uso. Os
				serviços são oferecidos apenas aos Usuários, para sua utilização pessoal
				e intransferível, de modo que o Usuário concorda que é proibido ceder à
				terceiros, mesmo à parentes e amigos, seus dados pessoais (nome de
				usuário e senha). O Usuário não poderá usar a Plataforma de maneira que
				possa danificar, inutilizar, sobrecarregar ou prejudicar qualquer rede
				ou servidor BenefizClub. O Usuário não poderá tentar obter acesso não
				autorizado a qualquer parte da Plataforma, outras contas, sistemas de
				computadores, ou redes conectadas a servidores do BenefizClub, através
				de senha, hacking ou de mineração de dados. O Usuário não poderá obter
				ou tentar obter materiais ou informações através de quaisquer meios
				intencionalmente não disponibilizados. A conduta indevida do Usuário, a
				critério do BenefizClub, poderá ensejar o cancelamento imediato de sua
				conta, com a devolução apenas dos créditos comprados pelo Usuário, não
				aqueles bonificados. O BenefizClub poderá excluir o cadastro do Usuário,
				cancelando eventual Assinatura, de forma imediata e sem qualquer direito
				à questionamentos, caso o Usuário viole os deveres e obrigações
				estabelecidos aqui nestes Termos de Uso. Ao aceitar Política de
				Privacidade, o usuário declara que está ciente e que concorda com a
				coleta e tratamento de seus dados.
			</p>

			<p className="font-bold text-xl">
				Da Licença Limitada e Direitos Autorais
			</p>

			<p>
				Todos os direitos intelectuais e industriais sobre a Plataforma,
				incluindo sem se limitar ao site, aplicativo, operações, software,
				hardware, domínio, logomarcas, emblemas, logotipos, design de páginas e
				peças publicitárias, estrutura, conteúdos, informações, Ferramentas do
				Programa, Símbolos e Expressões, que caracterizem os serviços e a marca
				BenefizClub, são de única e exclusiva propriedade do BenefizClub e em
				nenhum caso se entenderá que ao Usuário é concedido algum tipo de
				direito sobre os mesmos ou o seu uso sem autorização expressa e por
				escrito do representante legal do BenefizClub.
			</p>

			<p className="font-bold text-xl">Da Proteção de Dados</p>

			<p>
				O BenefizClub processará e utilizará os dados dos Usuários obtidos e
				necessários para a prestação dos serviços, os quais estarão protegidos
				de acordo com as regras da política de privacidade do BenefizClub. O
				BenefizClub tomará todas as medidas possíveis para manter a
				confidencialidade e a segurança descritas nesta cláusula, porém, não
				responderá por prejuízo que possa ser derivado da violação dessas
				medidas por terceiros que utilizem as redes públicas ou a internet para
				acessar as informações de Usuários.
			</p>

			<p className="font-bold text-xl">Da Disponibilidade dos Serviços</p>

			<p>
				O Usuário tem conhecimento de que a prestação dos serviços depende de
				aspectos alheios ao controle do BenefizClub, razão pela qual o
				BenefizClub não pode garantir o acesso e uso continuado ou ininterrupto
				da Plataforma, cujas funcionalidades podem, eventualmente, não estar
				disponíveis devido a problemas técnicos ou falhas de Internet nos links
				ou Ferramentas, não sendo lícito aos Usuários imputar responsabilidade
				ao BenefizClub, exigir indenização de qualquer natureza em virtude da
				impossibilidade de acesso à Plataforma, bem como por danos indiretos que
				surjam em conexão a prestação dos serviços previstos nestes Termo de
				Uso. Independentemente disso, o BenefizClub se empenhará para que os
				serviços estejam sempre disponíveis aos Usuários. Acordam as partes que
				a responsabilidade por danos de qualquer origem, que decorram da
				prestação dos serviços previstos nestes Termo de Uso, não excederão o
				valor pago pelo Usuário para adesão, de forma a manter sempre
				equilibrada a relação contratual.
			</p>

			<p className="font-bold text-xl">Garantias e Responsabilidade</p>

			<p>
				O BenefizClub garante e se responsabiliza pelo serviço de intermediação
				das modalidades de Benefícios disponibilizadas aos Usuários pelo
				estabelecimento parceiro. O Usuário reconhece e concorda que a
				remuneração paga ao BenefizClub se refere apenas ao serviço de registro
				da opção escolhida pelo Usuário e a transmissão ao estabelecimento
				parceiro escolhido, sendo certo que, o BenefizClub não possui nenhum
				controle e não poderá ser responsabilizado pelos serviços prestados pelo
				estabelecimento parceiro ao Usuário. Esses serviços serão prestados pelo
				estabelecimento parceiro em seu próprio nome e por sua conta e risco.
				Somente o estabelecimento parceiro, escolhido pelo Usuário, será
				responsável pelo cumprimento da opção registrada, bem como pela
				concessão do mencionado desconto. Ainda assim, o BenefizClub se
				compromete a assistir o Usuário em casos de problemas na utilização da
				opção feitas com a intermediação do BenefizClub. Nesses casos, os
				Usuários deverão entrar em contato com o BenefizClub, através do e-mail:
				descontos.dclube@gmail.com.
			</p>

			<p className="font-bold text-xl">Da Cessão de Direitos</p>

			<p>
				O Usuário declara-se ciente de que o BenefizClub poderá transferir a
				terceiros, total ou parcialmente, os seus direitos e deveres decorrentes
				destes Termos de Uso, incluindo toda a sua base de dados de Usuários,
				principalmente em caso de qualquer alteração em sua composição
				societárias, mediante simples comunicação ao Usuário, o qual concorda,
				desde já, com a adesão automática aos termos e condições de uso da nova
				empresa que porventura assuma o BenefizClub.
			</p>

			<p className="font-bold text-xl">Das Alterações dos Termos de Uso</p>

			<p>
				O BenefizClub reserva-se o direito de alterar os presentes Termos de Uso
				a qualquer momento, a seu exclusivo critério, sempre que julgar
				necessário, visando o aprimoramento e melhoria dos serviços prestados,
				por motivos legais, regulatórios e técnicos, ou devido a mudanças nos
				serviços fornecidos e/ou layout da Plataforma. O novo teor dos Termos de
				Uso entrará em vigor no prazo de 24 (vinte e quatro) horas contadas da
				publicação das modificações na Plataforma, de modo que o Usuário deverá
				se manifestar por e-mail, caso não concorde com as alterações
				realizadas. Neste caso, o vínculo contratual deixará de existir, desde
				que não haja débitos em aberto em nome do Usuário. Não havendo
				manifestação no prazo estipulado, entender-se-á que o Usuário aceitou
				tacitamente as novas condições dos Termos de Uso e o contrato continuará
				vinculando as partes para todos os fins. Caso o Usuário não esteja de
				acordo com as alterações, este poderá simplesmente cessar a utilização
				dos serviços, a qualquer momento, sem qualquer ônus ou encargo.
			</p>

			<p className="font-bold text-xl">Opiniões dos Usuários</p>

			<p>
				O Usuário, por meio deste documento, concede ao BenefizClub o direito de
				exibir na Plataforma do BenefizClub as avaliações e comentários feitos a
				respeito das experiências e usos da Assinatura nos estabelecimentos
				parceiros. As avaliações e comentários das experiências do Usuário
				deverão estar em consonância com as regras listadas a seguir. Os
				Usuários responderão pelo conteúdo e opiniões expressadas nas
				avaliações.
			</p>

			<p className="font-bold text-xl">
				Condições para a publicação de opiniões:
			</p>

			<p>
				São requisitos cumulativos para a publicação de opinião: ter capacidade
				civil plena, ou seja, ter 18 anos completos ou mais; ter cadastro ativo
				no BenefizClub; ter utilizado uma Assinatura ativa no estabelecimento
				parceiro. Para evitar qualquer conflito de interesse e por razões
				evidentes de objetividade, os Usuários que trabalham em estabelecimentos
				parceiros ou afins não poderão ter suas avaliações publicadas na
				Plataforma. A opinião deve restringir-se ao estabelecimento parceiro
				visitado pelo usuário. O BenefizClub poderá, discricionariamente,
				proibir a publicação da avaliação do Usuário na ocorrência de quaisquer
				das seguintes hipóteses:
				<p>
					{" "}
					- Não adesão do usuário às "Condições para publicação de opiniões"
					acima;
				</p>
				<p>
					{" "}
					- Possibilidade de o BenefizClub ser responsabilizado civil ou
					criminalmente;
				</p>
				<p> - Texto inclui insultos ou termos vulgares;</p>
				<p>
					{" "}
					- Dados de identificação do usuário contém insultos ou termos
					vulgares;
				</p>
				<p> - Texto contém símbolos ou sentenças desprovidos de significado;</p>
				<p>
					{" "}
					- Conteúdo (texto, documento, imagem, etc.) não está relacionado ao
					assunto em discussão;
				</p>
				<p> - Opinião contém um conflito de interesse específico;</p>
				<p> - Texto é ininteligível;</p>
				<p>
					{" "}
					- Usuário fez um comentário inapropriado sobre outra pessoa ou a
					opinião dela;
				</p>
				<p>
					{" "}
					- Conteúdo a ser publicado contém dados pessoais, tais como nome e
					sobrenome de indivíduos que não são pessoas públicas, um número de
					telefone, um endereço físico ou e-mail específico;
				</p>
				<p>
					{" "}
					- Texto contém um número de cartão de crédito, CPF, conta de banco ou
					qualquer outro dado que facilite ou possibilite apropriação indevida
					de identidade;
				</p>
				<p> - Texto inclui convocação para ação legal;</p>
				<p>
					{" "}
					- Conteúdo contém menções a sites, hyperlinks, URLs, endereços de
					e-mail ou números de telefone;
				</p>
				<p> - Texto é claramente um spam;</p>
				<p>
					{" "}
					- Texto contém qualquer comentário racista ou preconceituoso sobre
					assuntos relacionados ao grupo social, raça, classe econômica,
					religião ou orientação sexual de um indivíduo. Caso os usuários
					publiquem conteúdo falso, o BenefizClub, após proceder à recusa ou
					eliminação da avaliação, poderá cancelar o registro do Usuário e
					apagar todas as suas avaliações.
				</p>
			</p>

			<p className="font-bold text-xl">Moderação das Avaliações</p>

			<p>
				As avaliações serão moderadas pelo BenefizClub de modo a garantir sua
				concordância com as condições gerais listadas acima e serão publicadas,
				rejeitadas ou apagadas, conforme decisão do BenefizClub. Toda publicação
				será moderada por uma pessoa física. Usuários que tiverem uma avaliação
				publicada poderão pedir que ela seja apagada, enviando solicitação
				escrita para: clubedvantagens@gmail.com. O BenefizClub poderá exibir as
				avaliações pelo prazo de até 3 anos, contados do dia da publicação. Caso
				o restaurante encerre as suas atividades ou o seja alterado o titular de
				sua propriedade, as publicações a seu respeito poderão ser apagadas
				antes do prazo acima indicado. Para simplificar a leitura das
				publicações pelos Usuários do BenefizClub considera-se autorizada pelos
				Usuários do BenefizClub a inclusão, ao lado da publicação, dos seguintes
				dados: data da experiência/uso da Assinatura, nome e primeira letra do
				sobrenome do Usuário. Os usuários poderão acessar, alterar, retificar e
				eliminar seus dados pessoais, bem como opor-se ao uso deles, caso haja
				razões legítimas. Os Usuários afirmarão tais direitos através de
				solicitação escrita ao e-mail clubedvantagens@gmail.com.
			</p>

			<p className="font-bold text-xl">Das Disposições Gerais</p>

			<p>
				Se qualquer previsão do presente Termo de Uso for tida como inválida ou
				inexecutável em conformidade com a lei aplicável, o dispositivo inválido
				ou inexequível será considerado substituído por um dispositivo válido e
				exequível que se equipare da melhor maneira possível à intenção do
				dispositivo original e todos os termos e condições restantes continuarão
				em pleno vigor e efeito.
			</p>

			<p className="font-bold text-xl">Da Legislação e Foro</p>

			<p>
				Os presentes Termos de Uso serão regidos de acordo com a legislação
				brasileira vigente na data de adesão do Usuário e qualquer controvérsia
				decorrente de seus termos, condições ou cláusulas, terá como foro
				competente o Foro Central da Comarca de São Bento do Sul, SC,
				renunciando as partes a qualquer outro, por mais privilegiado que seja.
			</p>
		</div>
	);
};

export default Terms;
