import type { PartnerInputs } from "../components/PartnerDetails.tsx";
import pruneEmpty from "./pruneEmpty.ts";
import { toBase64 } from "./utils.ts";

const createPartnerPayload = async ({
	uploadPhoto,
	discounts,
	...rest
}: PartnerInputs) => {
	const removedEmpty = pruneEmpty(rest);

	return {
		...removedEmpty,
		uploadPhoto:
			typeof uploadPhoto === "string"
				? uploadPhoto
				: // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
					await toBase64(uploadPhoto?.[0]!),
		discounts: Object.entries(discounts).flatMap(([key, value]) => {
			return value.map((v: any) => ({
				...v,
				discount: Number(v.discount) / 100,
				weekday: key,
				restrictions: v.restrictions?.map(
					({ restriction }: any) => restriction,
				),
			}));
		}),
	};
};

export default createPartnerPayload;
