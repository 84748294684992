import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { useFieldArray } from "react-hook-form";
import InputText from "./InputText.tsx";

type RestrictionsProps = { name: string };

const Restrictions = ({ name }: RestrictionsProps) => {
	const { fields, append, remove } = useFieldArray({
		name, // unique name for your Field Array
	});

	return (
		<div>
			<div className="flex  items-center">
				<p className="font-bold ">Restrições</p>
				<button
					type="button"
					className="btn btn-circle btn-ghost"
					onClick={() => append({ restriction: "" })}
				>
					<PlusCircleIcon className="size-5" />
				</button>
			</div>
			<div>
				{fields.map(({ id }, index) => (
					<div key={id} className="flex items-center">
						<InputText
							name={`${name}.${index}.restriction`}
							label={`Restrição ${index + 1}`}
							size="full"
							required
							type="textarea"
							className="h-32"
						/>
						<button
							type="button"
							className="btn btn-circle btn-ghost mt-9"
							onClick={() => remove(index)}
						>
							<MinusCircleIcon className="size-6 fill-red-500" />
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default Restrictions;
