import DailyDiscount from "./DailyDiscount.tsx";

const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const DiscountSection = () => {
	return (
		<div className="flex flex-col gap-4">
			<p className="text-xl font-bold">Descontos</p>
			<div className="grid grid-cols-2 gap-4">
				{weekdays.map((weekday) => (
					<DailyDiscount key={weekday} weekday={weekday} />
				))}
			</div>
		</div>
	);
};

export default DiscountSection;
