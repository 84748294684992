import { XCircleIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import { Enums } from "../helpers/enums.ts";

type ImagePickerProps = { photos: string[]; name: string };

const ImagePicker = ({ photos, name }: ImagePickerProps) => {
	const { data } = useQuery<string[]>({
		queryKey: ["googleImages", name],
		queryFn: () =>
			axios.post("getGoogleMapsImages", photos).then((res) => res.data),
		// @ts-expect-error bla
		enabled: document.getElementById(Enums.pickCustomerModal)?.open,
	});

	const { setValue } = useFormContext();

	if (!data) {
		return <div>Carregando...</div>;
	}

	return (
		<dialog id={Enums.pickCustomerModal} className="modal">
			<div className="modal-box overflow-scroll max-w-screen-xl">
				<button
					type="button"
					className="absolute right-4 top-4"
					onClick={(e) => {
						e.stopPropagation();
						// @ts-expect-error bla
						document.getElementById(Enums.pickCustomerModal)?.close();
					}}
				>
					<XCircleIcon className="size-8" />
				</button>
				<h3 className="font-bold text-lg mb-4">Selecione a imagem</h3>
				<div className="flex flex-wrap gap-4">
					{data.map((photo) => (
						<button
							type="button"
							onClick={(e) => {
								e.stopPropagation();
								setValue("uploadPhoto", photo);
								// @ts-expect-error bla
								document.getElementById(Enums.pickCustomerModal)?.close();
							}}
						>
							<img
								src={photo}
								className="border rounded w-72 h-44 object-cover"
							/>
							Selecionar
						</button>
					))}
				</div>
			</div>
		</dialog>
	);
};

export default ImagePicker;
