import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { TLabelValue, TPartnerDetails } from "../types.ts";
import DiscountSection from "./DiscountSection.tsx";
import InputText from "./InputText.tsx";
import SelectInput from "./SelectInput.tsx";

export type Discount = {
	discount: number;
	from: string;
	to: string;
	restrictions: { restriction: string }[];
};

export type PartnerInputs = TPartnerDetails & {
	discounts: Record<string, Discount[]>;
	uploadPhoto: File[] | string | undefined;
};

type Props = {
	categories: TLabelValue[];
	showAddressSection: boolean;
	showTitle?: boolean;
	isLoading?: boolean;
};

const PartnerDetails = ({
	categories,
	showAddressSection,
	showTitle = true,
	isLoading,
}: Props) => {
	const {
		formState: { isSubmitted },
	} = useFormContext();

	const { register } = useFormContext();

	const uploadPhoto = useWatch({
		name: "uploadPhoto",
	});
	const name = useWatch({
		name: "name",
	});
	const watchedDiscounts = useWatch({
		name: "discounts",
	});

	const errorMessage = React.useMemo(() => {
		if (!isSubmitted) {
			return undefined;
		}

		if (!watchedDiscounts) {
			return "Pelo menos um desconto tem que ser cadastrado";
		}

		if (!uploadPhoto || !uploadPhoto[0]) {
			return "A foto do parceiro é obrigatória";
		}
	}, [isSubmitted, uploadPhoto, watchedDiscounts]);

	return (
		<div className="gap-4 flex flex-col">
			{showTitle && <p className="text-3xl">{name}</p>}
			<div className="flex flex-col gap-8">
				<div className="gap-4 flex flex-wrap">
					<div className="flex justify-between flex-1 gap-4">
						<div className="flex-1">
							<InputText<PartnerInputs>
								name="name"
								label="Nome"
								required
								size="full"
							/>
							<div className="flex flex-wrap gap-2">
								<SelectInput
									label="Categoria"
									placeholder="Selecione"
									name="category"
									required
									options={categories}
								/>
								<InputText<PartnerInputs>
									name="phone"
									label="Telefone"
									size="sm"
									mask="(99) 99999-9999"
								/>
								<InputText<PartnerInputs> name="instagram" label="Instagram" />
								<InputText<PartnerInputs> name="website" label="Website" />
								<InputText<PartnerInputs>
									name="email"
									label="Email"
									inputType="email"
								/>
							</div>
						</div>
						<div>
							<button
								type="button"
								// @ts-expect-error bla
								onClick={() => document.getElementById("uploadPhoto").click()}
							>
								<img
									src={
										!!uploadPhoto && typeof uploadPhoto?.[0] === "object"
											? URL.createObjectURL(uploadPhoto[0])
											: typeof uploadPhoto === "string"
												? uploadPhoto
												: "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
									}
									className="border rounded w-72 h-44 object-cover"
								/>
								Trocar foto
							</button>
							<input
								{...register("uploadPhoto")}
								id="uploadPhoto"
								placeholder="Selecione uma imagem"
								type="file"
								className="hidden"
							/>
						</div>
					</div>
					<InputText<PartnerInputs>
						name="overview"
						label="Descrição"
						type="textarea"
						size="full"
					/>
					{!showAddressSection && (
						<InputText<PartnerInputs>
							name="formattedAddress"
							label="Endereço"
							size="full"
							disabled
						/>
					)}
				</div>
				{showAddressSection && (
					<div className="flex flex-col gap-4">
						<p className="text-lg font-bold">Endereço</p>
						<div className="gap-4 flex flex-wrap">
							<InputText<PartnerInputs>
								name="address.street"
								label="Rua"
								size="lg"
								disabled
							/>
							<InputText<PartnerInputs>
								name="address.number"
								label="Número"
								size="xs"
								disabled
							/>
							<InputText<PartnerInputs>
								name="address.complement"
								label="Complemento"
								size="lg"
							/>
							<InputText<PartnerInputs>
								name="address.neighborhood"
								label="Bairro"
								disabled
							/>
							<InputText<PartnerInputs>
								name="address.state"
								label="Estado"
								size="xs"
								disabled
							/>
							<InputText<PartnerInputs>
								name="address.city"
								label="Cidade"
								disabled
							/>
							<InputText<PartnerInputs>
								name="address.zipCode"
								label="CEP"
								size="sm"
								disabled
							/>
						</div>
					</div>
				)}
				<DiscountSection />
				<div className="flex flex-col gap-2">
					{!!errorMessage && (
						<div className="label">
							<span className="label-text-alt text-red-500 flex gap-2 text-sm">
								<ExclamationTriangleIcon className="size-4" />
								{errorMessage}
							</span>
						</div>
					)}
					<button className="btn btn-primary" type="submit">
						{isLoading && <span className="loading loading-spinner" />} Salvar
					</button>
				</div>
			</div>
		</div>
	);
};

export default PartnerDetails;
