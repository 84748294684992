import type * as React from "react";

type CheckInAlertProps = {
	status?: CheckInStatus;
	hasError: boolean;
	isLoading: boolean;
	onConfirmCheckIn: () => void;
};

export enum CheckInStatus {
	USER_CHECKED_IN = "USER_CHECKED_IN",
	USER_NOT_CHECKED_IN = "USER_NOT_CHECKED_IN",
	USER_NOT_FOUND = "USER_NOT_FOUND",
	USER_NOT_SUBSCRIBED = "USER_NOT_SUBSCRIBED",
}

const CheckInAlert: React.FC<React.PropsWithChildren<CheckInAlertProps>> = ({
	status,
	hasError,
	isLoading,
	onConfirmCheckIn,
}) => {
	if (hasError) {
		return (
			<div role="alert" className="alert alert-error">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 shrink-0 stroke-current"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<span>
					Página do parceiro não encontrada. Por favor, verifique a URL e tente
					novamente.
				</span>
			</div>
		);
	}
	if (status === CheckInStatus.USER_NOT_CHECKED_IN) {
		return (
			<div role="alert" className="alert alert-warning">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 shrink-0 stroke-current"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
				<span>
					Usuário com assinatura válida mas não ativou o desconto. Gostaria de
					validar o desconto mesmo assim?
				</span>
				<div className="flex gap-2">
					<button
						onClick={onConfirmCheckIn}
						className="btn btn-sm text-white"
						disabled={isLoading}
					>
						{isLoading && <span className="loading loading-spinner" />}Validar
					</button>
				</div>
			</div>
		);
	}

	if (status === CheckInStatus.USER_NOT_FOUND) {
		return (
			<div role="alert" className="alert alert-error">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 shrink-0 stroke-current"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<span>
					Cliente não cadastrado no aplicativo. Por favor verfique o email ou
					CPF inserido.
				</span>
			</div>
		);
	}

	if (status === CheckInStatus.USER_NOT_SUBSCRIBED) {
		return (
			<div role="alert" className="alert alert-error">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 shrink-0 stroke-current"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<span>
					Cliente não possui assinatura válida no aplicativo. Por favor pedir
					para entrar em contato conosco no whatsapp (47) 99291-4482.
				</span>
			</div>
		);
	}

	if (status === CheckInStatus.USER_CHECKED_IN) {
		return (
			<div role="alert" className="alert alert-success">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 shrink-0 stroke-current"
					fill="none"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<span>Confirmar validação do desconto?</span>
				<div className="flex gap-2">
					<button
						onClick={onConfirmCheckIn}
						className="btn btn-sm text-white"
						disabled={isLoading}
					>
						{isLoading && <span className="loading loading-spinner" />}Confirmar
					</button>
				</div>
			</div>
		);
	}

	return <div />;
};

export default CheckInAlert;
