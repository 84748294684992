import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useFieldArray } from "react-hook-form";
import InputText from "./InputText.tsx";
import Restrictions from "./Restrictions.tsx";

type DailyDiscountProps = { weekday: string };

const getWeekdayName = (weekday: string) => {
	switch (weekday) {
		case "mon":
			return "Segunda-feira";
		case "tue":
			return "Terça-feira";
		case "wed":
			return "Quarta-feira";
		case "thu":
			return "Quinta-feira";
		case "fri":
			return "Sexta-feira";
		case "sat":
			return "Sábado";
		case "sun":
			return "Domingo";
		default:
			return "";
	}
};

const DailyDiscount = ({ weekday }: DailyDiscountProps) => {
	const name = `discounts.${weekday}`;

	const { fields, append, remove } = useFieldArray({
		name,
	});

	return (
		<div className="flex flex-col gap-2 border p-4 rounded border-gray-600">
			<div className="flex items-center  ">
				<p className="font-bold text-lg">{getWeekdayName(weekday)}</p>
				<button
					type="button"
					className="btn btn-circle btn-ghost"
					onClick={() => append({ discount: 30, from: "09:00", to: "21:00" })}
				>
					<PlusCircleIcon className="size-6 fill-primary" />
				</button>
			</div>
			<div className="flex flex-col gap-4">
				{fields.map(({ id }, index, { length }) => (
					<div
						key={id}
						className={clsx("flex flex-col gap-1 border-gray-600", {
							"border-b": index !== length - 1,
							"pb-4": index !== length - 1,
						})}
					>
						<div key={id} className="flex gap-2 items-center">
							<InputText
								inputType="number"
								name={`${name}.${index}.discount`}
								label="Desconto (%)"
								size="xs"
							/>
							<InputText
								inputType="time"
								name={`${name}.${index}.from`}
								label="De"
								size="sm"
							/>
							<InputText
								inputType="time"
								name={`${name}.${index}.to`}
								label="Às"
								size="sm"
							/>
							<button
								type="button"
								className="btn btn-circle btn-ghost mt-9 -ml-2"
								onClick={() => remove(index)}
							>
								<MinusCircleIcon className="size-6 fill-red-500" />
							</button>
						</div>
						<Restrictions name={`${name}.${index}.restrictions`} />
					</div>
				))}
			</div>
		</div>
	);
};

export default DailyDiscount;
