import logo from "../assets/logo.png";

export default function Contact() {
	return (
		<div className="isolate px-6 py-24 sm:py-32 lg:px-8 w-screen h-screen">
			<div className="mx-auto max-w-2xl sm:text-center flex flex-col gap-2 justify-center items-center">
				<img src={logo} height={200} style={{ height: 400, width: 400 }} />
				<h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
					Alguma dúvida?
				</h2>
				<p className="mt-2 text-lg leading-8 text-gray-200">
					Nos contate para obter suporte técnico, relatar um bug ou falar sobre
					qualquer outra coisa.
				</p>
				<a
					href="mailto:clubedvantagens@gmail.com"
					className="text-sm font-semibold leading-6 text-primary mt-4"
				>
					Contate-nos aqui <span aria-hidden="true">&rarr;</span>
				</a>
			</div>
		</div>
	);
}
