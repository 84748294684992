import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import LoginModal from "../components/LoginModal.tsx";
import firebaseInstance from "../helpers/firebaseInstance.ts";

const DeleteAccount = () => {
	const { isPending, mutate } = useMutation({
		mutationFn: async () => {
			const token = await firebaseInstance
				.auth()
				?.currentUser?.getIdToken(true);

			return axios.delete("deleteUser", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		},
		onSuccess: () => {
			// @ts-expect-error - showModal is not a function
			document.getElementById("login_modal").close();
			firebaseInstance.auth().signOut();
			toast.success(
				"Sucesso! Sua conta foi deletada com sucesso. Esperamos que você volte em breve!",
			);
		},
	});

	const handleDeleteAccount = () => {
		console.log("> user", firebaseInstance.auth().currentUser?.toJSON());

		if (firebaseInstance.auth().currentUser) {
			// @ts-expect-error - showModal is not a function
			document.getElementById("login_modal").close();
			mutate();
		} else {
			// @ts-expect-error - showModal is not a function
			document.getElementById("login_modal").showModal();
		}
	};

	return (
		<div className="flex container max-w-4xl flex-col p-6 justify-between h-screen">
			<div className="flex flex-col gap-5">
				<h1 className="text-3xl font-bold">
					Você realmente deseja deletar essa conta?
				</h1>

				<p>
					<span>Esta é uma ação irreversível! </span>
					<span>
						Ao seguir com a exclusão de conta, seu e-mail será desvinculado da
						BenefizClub e também deixaremos de armazenar seus dados
					</span>
				</p>

				<p>
					Se você for assinante, lembre-se que{" "}
					<span style={{ fontWeight: "bold" }}>
						a exclusão da conta não cancela automaticamente sua assinatura.
					</span>
				</p>

				<p>
					Para modificar ou ver mais detalhes da sua assinatura, accesse "suas
					assinaturas" no app store (no caso de aparelhos de celular IOS) ou
					google play store (no caso de de aparelhos de celular android).
				</p>

				<p>
					Caso tenha qualquer dúvida, voce pode entrar em contato com o nosso
					whataspp{"  "}
					<a
						className="font-bold underline text-primary"
						href="whatsapp://send?text=Olá, eu vim da BenefizClub D&phone=+5547992914482"
						target="_blank"
						rel="noreferrer"
					>
						aqui
					</a>
				</p>
			</div>
			<div className="flex flex-col mt-4 gap-6">
				<p className="font-bold">Deseja continuar com a exclusão da conta?</p>
				<button
					className="btn btn-primary btn-outline"
					onClick={handleDeleteAccount}
					disabled={isPending}
				>
					{isPending && <span className="loading loading-spinner" />}Cancelar a
					conta
				</button>
			</div>
			<LoginModal id="login_modal" onUserLoggedIn={handleDeleteAccount} />
		</div>
	);
};

export default DeleteAccount;
