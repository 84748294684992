import type React from "react";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import CheckInAlert, { type CheckInStatus } from "./CheckInAlert";
import CheckInInfo from "./CheckInInfo";

/**
 * Format to Brazilian currency
 */
export const maskToCurrency = ({ nextState }: any) => {
	const { value } = nextState || {};

	let amountFormatted = value?.replace?.(/\D/g, "");
	amountFormatted = amountFormatted?.replace?.(/^0+/g, "");

	if (amountFormatted?.length === 2) {
		return {
			...nextState,
			value: `${amountFormatted}`,
			selection: {
				start: amountFormatted.length + 3,
				end: amountFormatted.length + 3,
			},
		};
	}

	const amountFormattedWithComma = amountFormatted?.replace?.(
		/(?=\d{2})(\d{2})$/,
		",$1",
	);
	const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
		/(\d)(?=(\d{3})+(?!\d))/g,
		"$1.",
	);

	if (amountFormattedWithDot) {
		return {
			...nextState,
			value: `${amountFormattedWithDot}`,
			selection: {
				start: amountFormattedWithDot.length + 3,
				end: amountFormattedWithDot.length + 3,
			},
		};
	}

	return nextState;
};

interface CheckInConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	discountData: any; // Replace 'any' with the actual type of your discount data
	checkinStatus: CheckInStatus;
	isError: boolean;
	isLoading: boolean;
	onConfirmCheckIn: (providedDiscount?: number) => void;
}

const CheckInConfirmationModal: React.FC<CheckInConfirmationModalProps> = ({
	isOpen,
	onClose,
	discountData,
	checkinStatus,
	isError,
	isLoading,
	onConfirmCheckIn,
}) => {
	const [providedDiscount, setProvidedDiscount] = useState<string>("");
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (isOpen) {
			setProvidedDiscount("");
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOpen]);

	if (!isOpen) return null;

	const handleConfirmCheckIn = () => {
		const discount = Number.parseFloat(providedDiscount.replace(",", "."));
		onConfirmCheckIn(
			Number.isNaN(discount) || discount < 0 ? undefined : discount,
		);
	};

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 flex-col ">
			<div className="bg-gray-700 p-6 rounded-lg max-w-2xl md:h-auto h-full w-full relative gap-4 flex flex-col justify-between">
				<div className="flex flex-col">
					<button
						type="button"
						className="absolute top-2 right-2 text-gray-400 hover:text-white"
						onClick={onClose}
						aria-label="Close"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
					<h2 className="text-2xl font-bold mb-4">Confirmar Check-in</h2>
					{!!discountData && <CheckInInfo {...discountData} />}
					<div className="mt-8">
						<label
							htmlFor="providedDiscount"
							className="block text-sm font-medium text-gray-300 mb-2"
						>
							Desconto fornecido (R$)
						</label>
						<div className="relative">
							<span className="absolute inset-y-0 left-0 pl-2 flex items-center text-gray-400">
								R$
							</span>
							<InputMask
								mask="9999999999"
								maskPlaceholder={null}
								alwaysShowMask={false}
								beforeMaskedStateChange={maskToCurrency}
								// @ts-ignore
								ref={inputRef}
								id="providedDiscount"
								value={providedDiscount}
								onChange={(e) => setProvidedDiscount(e.target.value)}
								className="w-full pl-8 pr-3 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
								placeholder="10,99"
							/>
						</div>
					</div>
				</div>
				<CheckInAlert
					status={checkinStatus}
					hasError={isError}
					isLoading={isLoading}
					onConfirmCheckIn={handleConfirmCheckIn}
				/>
			</div>
		</div>
	);
};

export default CheckInConfirmationModal;
