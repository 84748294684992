import firebase from "firebase/compat/app";
// @ts-expect-error - The firebaseui type definitions are incorrect.
window.firebase = firebase;

const config = {
	apiKey: "AIzaSyDDBLPHYObJQBnWCQdAuDhz-W0_24xADb4",
	authDomain: "d-club-e36c3.firebaseapp.com",
	projectId: "d-club-e36c3",
	storageBucket: "d-club-e36c3.appspot.com",
	messagingSenderId: "34809820207",
	appId: "1:34809820207:web:c2f1204a757a2999e602da",
	measurementId: "G-TQR8PSF9GV",
};

export const firebaseApp = !firebase.apps.length
	? firebase.initializeApp(config)
	: firebase.app();

export default firebase;
