import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { Enums } from "../helpers/enums.ts";
import firebaseInstance from "../helpers/firebaseInstance.ts";
import { queryClient } from "../helpers/queryClient.ts";

type DeleteCustomerModalProps = { name: string; id: number };

const DeletePartnerModal = ({ name, id }: DeleteCustomerModalProps) => {
	const { mutate, isPending } = useMutation({
		mutationFn: async () => {
			const token = await firebaseInstance.auth().currentUser?.getIdToken(true);
			return axios
				.delete("removePartner", {
					params: { id },
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((res) => res.data);
		},
		onSuccess: () => {
			// @ts-expect-error bla
			document.getElementById(`${Enums.deleteCustomerModal}_${id}`)?.close();
			queryClient.invalidateQueries({ queryKey: ["partners"] });
			toast.success(`Sucesso! ${name} foi deletado com sucesso`);
		},
		onError: (error) => {
			const e = typeof error === "string" ? error : error.message;
			toast.error(`Erro! ${e}`);
		},
	});

	return (
		<dialog
			id={`${Enums.deleteCustomerModal}_${id}`}
			className="modal"
			onClick={(e) => e.stopPropagation()}
		>
			<div className="modal-box overflow-scroll">
				<button
					type="button"
					className="absolute right-4 top-4"
					onClick={(e) => {
						e.stopPropagation();
						document
							.getElementById(`${Enums.deleteCustomerModal}_${id}`)
							// @ts-expect-error bla
							?.close();
					}}
				>
					<XCircleIcon className="size-8" />
				</button>
				<h3 className="font-bold text-lg mb-4">Deletar</h3>
				<div className="flex flex-col gap-4">
					<p>
						Tem certeza que deseja excluir{" "}
						<span className="font-bold">{name}</span>?
					</p>

					<div className="flex ">
						<button
							className="btn btn-primary w-48"
							onClick={(e) => {
								e.stopPropagation();
								mutate();
							}}
						>
							{isPending && <span className="loading loading-spinner" />}{" "}
							Deletar
						</button>
					</div>
				</div>
			</div>
		</dialog>
	);
};

export default DeletePartnerModal;
