import React from "react";
import { Navigate } from "react-router-dom";
import firebaseInstance from "../../helpers/firebaseInstance.ts";

type Props = { children: React.ReactNode };

export const ProtectedRoute = ({ children }: Props) => {
	const [user, setUser] = React.useState<firebaseInstance.User | null>(null);

	const [shouldCheck, setShouldCheck] = React.useState(true);

	React.useEffect(() => {
		// firebaseInstance.auth().signOut();
		firebaseInstance.auth().onAuthStateChanged((user) => {
			setUser(user);
			setShouldCheck(false);
		});
	}, []);

	if (shouldCheck) {
		return null;
	}

	if (!user) {
		// user is not authenticated
		return <Navigate to="/login" />;
	}
	return children;
};
