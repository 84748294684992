import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { get } from "lodash";
import type { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import { type FieldValues, type Path, useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";

type InputTextProps<T extends FieldValues> = Omit<
	InputHTMLAttributes<HTMLInputElement>,
	"size"
> & {
	label: string;
	name: Path<T>;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	type?: "default" | "textarea";
	inputType?: HTMLInputTypeAttribute;
	size?: "xs" | "sm" | "md" | "lg" | "full";
	warning?: string;
	mask?: string;
};

const InputText = <T extends FieldValues>({
	name,
	label,
	placeholder,
	required,
	type = "default",
	size = "md",
	disabled,
	warning,
	inputType,
	className,
	mask,
	...rest
}: InputTextProps<T>) => {
	const {
		register,
		formState: { errors, touchedFields },
	} = useFormContext<T>();

	const hasError = !!get(errors, name) && !!get(touchedFields, name);

	const commonProps = {
		...register(name, { required }),
		placeholder,
		disabled,
	};

	const Input = mask ? InputMask : "input";

	const getContent = () => {
		if (type === "default") {
			return (
				<Input
					maskPlaceholder={null}
					{...rest}
					{...commonProps}
					// @ts-expect-error bla
					mask={mask}
					type={inputType}
					className={clsx("input input-bordered w-full text-sm", {
						"border-red-500": hasError,
						"!bg-gray-700 ": disabled,
					})}
				/>
			);
		}

		if (type === "textarea") {
			return (
				<textarea
					{...commonProps}
					className={clsx("textarea textarea-bordered h-28 textarea-md", {
						"border-red-500": hasError,
					})}
				/>
			);
		}
	};

	return (
		<label
			className={clsx(
				"form-control",
				{
					"w-28": size === "xs",
					"w-36": size === "sm",
					"w-60": size === "md",
					"w-96": size === "lg",
					"w-full": size === "full",
				},
				className,
			)}
		>
			<div className="label">
				<span className="label-text">{label}</span>
			</div>
			{getContent()}

			{!!warning ||
				(disabled && (
					<div className="label">
						<span className="label-text-alt text-warning-500 flex gap-2">
							<ExclamationTriangleIcon className="size-4" />
							{size === "xs" || size === "sm"
								? ""
								: warning || "Campo não pode ser alterado"}
						</span>
					</div>
				))}
			{hasError && (
				<div className="label">
					<span className="label-text-alt text-red-500 flex gap-2">
						<ExclamationTriangleIcon className="size-4" />
						Campo obrigatório
					</span>
				</div>
			)}
		</label>
	);
};

export default InputText;
